import React, { Component } from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Checkbox, Button, Icon, Row, Col, BackTop, Steps } from 'antd';

import styles from './supplyDetails.less';

import step1Png from '../../assets/product/step1.png';
import step21 from '../../assets/product/step21.png';
import step22 from '../../assets/product/step22.png';
import step3Png from '../../assets/product/step3.png';
import step4Png from '../../assets/product/step4.png';
import logo from 'assets/svg/logo.svg';
import supply1Png from '../../assets/supply/supply1.png';
import supply2Png from '../../assets/supply/supply2.png';
import supply3Png from '../../assets/supply/supply3.png';
import supply4Png from '../../assets/supply/supply4.png';
import supply5Png from '../../assets/supply/supply5.png';
import supply6Png from '../../assets/supply/supply6.png';
import supply7Png from '../../assets/supply/supply7.png';
import supply8Png from '../../assets/supply/supply8.png';
import verifiedPng from '../../assets/product/Verified.png';
import supplyHead from '../../assets/supply/supply_head.png';
import supplyFoot from '../../assets/supply/supply_foot.png';
import platform01 from '../../assets/support/platform01.png';
import platform02 from '../../assets/support/platform02.png';
import platform03 from '../../assets/support/platform03.png';
import platform04 from '../../assets/support/platform04.png';
import footerImg01 from '../../assets/banner/footerImg01.png';
import footerImg02 from '../../assets/banner/footerImg02.png';
import footerImg03 from '../../assets/banner/footerImg03.png';
import footerImg04 from '../../assets/banner/footerImg04.png';
import dsersLogo from '../../assets/banner/dsers.png';
import backTop from '../../assets/banner/backTop.png';

const Step = Steps.Step;

export default class SupplyDetails extends Component {
  state = {
    current: 0
  };

  goProduct = () => {
    window.location.href = '/app/'; // 首页
    localStorage.setItem('produstKey', 4);
  };

  next = () => {
    const current = this.state.current + 1;
    this.setState({ current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  stepClick = ind => {
    this.setState({
      current: ind
    });
  };

  render() {
    const { current } = this.state;

    const step1 = (
      <div className={styles.SupplyDetails_list1}>
        <div className={styles.SupplyDetails_txt}>
          <h3>Step 1 </h3>
          <h2>
            Filter products with <span>Verified</span> suppliers
          </h2>
          <p>
            Not all your products are supplied by verified suppliers <br />
            Filter out which products are provided first.<br />
            We will guide you to do this later<br />
            Don't forget to choose your store
          </p>
        </div>
        <div className={styles.SupplyDetails_img1}>
          <img src={step1Png} />
        </div>
      </div>
    );
    const step2 = (
      <div className={styles.SupplyDetails_list1}>
        <div className={styles.SupplyDetails_txt}>
          <h3>Step 2 </h3>
          <h2>
            Apply <span>Verified</span> supplier
          </h2>
          <p>
            Click Apply button to configure. <br />
            DSers WON'T change your default supplier until you enable.
          </p>
        </div>
        <div className={styles.SupplyDetails_img2}>
          <img src={step21} />
          <img src={step22} />
        </div>
      </div>
    );
    const step3 = (
      <div className={styles.SupplyDetails_list1}>
        <div className={styles.SupplyDetails_txt}>
          <h3>Step 3 </h3>
          <h2>Fill in Package request and enable</h2>
          <p>
            Let verified suppliers know if you have <br />
            special request to package.
          </p>
        </div>
        <div className={styles.SupplyDetails_img3}>
          <img src={step3Png} />
        </div>
      </div>
    );
    const step4 = (
      <div className={styles.SupplyDetails_list2}>
        <h3>Step 4 </h3>
        <h2>Fill in billing info and make pre-payment</h2>
        <p>
          To enable verified supplier project, <br />
          you need to fill in billing account info and make pre-payment first
        </p>
        <div className={styles.Guidance_list3_Img4}>
          <img src={step4Png} />
        </div>
      </div>
    );
    const step5 = (
      <div className={styles.SupplyDetails_list3}>
        <h3>Step 5 </h3>
        <h2>
          Now check how many products are with <br />
          Verified supplier by clicking Filter !
        </h2>
        <Button type="primary" onClick={this.goProduct}>
          Get Started
        </Button>
      </div>
    );

    const steps = [
      {
        title: 'Step1: Filter products',
        content: step1
      },
      {
        title: 'Step2: Apply',
        content: step2
      },
      {
        title: 'Step3: Enable',
        content: step3
      },
      {
        title: 'Step4: Prepay',
        content: step4
      },
      {
        title: 'Step5: Get started',
        content: step5
      }
    ];

    return (
      <>
        <div className={styles.supplyBox}>
          <div className={styles.header}>
            <img src={logo} width="141" />
            <Button type="primary" onClick={this.goProduct}>
              Get Started
            </Button>
          </div>
          <div className={styles.headTitle}>
            <h2>
              <img src={verifiedPng} />
              <p>
                <span>Verified </span>Ali Suppliers Project
              </p>
            </h2>
            <p>
              Never worry about finding exceptional suppliers – we’ve already
              found them for you.
            </p>
          </div>
          <div className={styles.head_img}>
            <img src={supplyHead} />
          </div>
          <div className={styles.content}>
            <h2>Why Verified Ali Suppliers Project</h2>
            <div className={styles.cont_list}>
              <ul>
                <li>
                  <img src={supply1Png} />
                  <h3>No More Credit Card Locked Up</h3>
                </li>
                <li>
                  <img src={supply2Png} />
                  <h3>No more payment rejection</h3>
                </li>
                <li>
                  <img src={supply3Png} />
                  <h3>Trusted suppliers</h3>
                </li>
                <li>
                  <img src={supply4Png} />
                  <h3>No more orders canceled</h3>
                </li>
                <li>
                  <img src={supply5Png} />
                  <h3>Fast shipping</h3>
                </li>
                <li>
                  <img src={supply6Png} />
                  <h3>Processing time 3~5 Days</h3>
                </li>
                <li>
                  <img src={supply7Png} />
                  <h3>7/24 Customer Service</h3>
                </li>
                <li>
                  <img src={supply8Png} />
                  <h3>Standard invoice</h3>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.head_img}>
            <img src={supplyFoot} />
          </div>
          <div className={styles.goBut}>
            <Button type="primary" onClick={this.goProduct}>
              Get Verified Product
            </Button>
          </div>
          <div className={styles.cont_Suppliers_tit}>
            <h2>Ali Suppliers Project</h2>
          </div>
          <div className={styles.cont_Suppliers_List}>
            <ul>
              <li>
                No More Credit Card Locked Up No more payment rejection Trusted
                suppliers No more orders canceled
              </li>
              <li>
                No More Credit Card Locked Up No more payment rejection Trusted
                suppliers No more orders canceled
              </li>
              <li>
                No More Credit Card Locked Up No more payment rejection Trusted
                suppliers No more orders canceled
              </li>
              <li>
                No More Credit Card Locked Up No more payment rejection Trusted
                suppliers No more orders canceled
              </li>
            </ul>
          </div>

          <div className={styles.cont_Secure_tit}>
            <h2>Secure Shipping</h2>
            <p>
              Never worry about the safety and efficiency of distribution –
              we’ve already arrange everything for you.
            </p>
          </div>
          <div className={styles.cont_Secure_List}>
            <ul>
              <li>
                Fast shipping Processing time 3~5 Days 7/24 Customer Service
                Standard invoice
              </li>
              <li>
                Fast shipping Processing time 3~5 Days 7/24 Customer Service
                Standard invoice
              </li>
              <li>
                Fast shipping Processing time 3~5 Days 7/24 Customer Service
                Standard invoice
              </li>
              <li>
                Fast shipping Processing time 3~5 Days 7/24 Customer Service
                Standard invoice
              </li>
            </ul>
          </div>

          <div className={styles.cont_join_tit}>
            <h2>How do I Join</h2>
            <div className={styles.cont_join_step}>
              <Steps progressDot current={current}>
                {steps.map((item, ind) => (
                  <Step
                    onClick={() => {
                      this.stepClick(ind);
                    }}
                    key={item.title}
                    title={item.title}
                  />
                ))}
              </Steps>
              <div className="steps-content">{steps[current].content}</div>
              <div className="steps-action">
                {current < steps.length - 1 && (
                  <Button
                    style={{ marginLeft: 20 }}
                    type="primary"
                    onClick={() => this.next()}
                  >
                    Next
                  </Button>
                )}
                {/* {
                  current === steps.length - 1
                  && <Button type="primary" onClick={() => this.goProduct()}>Get Started</Button>
                } */}

                {current > 0 && (
                  <Button onClick={() => this.prev()}>Previous</Button>
                )}
              </div>
            </div>
          </div>

          <div className={styles.goBut}>
            <Button type="primary" onClick={this.goProduct}>
              Get Verified Product
            </Button>
          </div>

          <div className={styles.relativePlatforms}>
            <h3>Partnership</h3>
            <ul>
              <li>
                <a
                  href="https://www.aliexpress.com/?spm=a2g01.7877950.1000002.1.4ae83bfcCWLnO5"
                  target="_blank"
                >
                  <img src={platform01} />
                </a>
              </li>
              <li>
                <a href="https://www.shopify.com/" target="_blank">
                  <img src={platform02} />
                </a>
              </li>
              <li>
                <a href="https://www.google.com/" target="_blank">
                  <img src={platform03} />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/" target="_blank">
                  <img src={platform04} />
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.banner_footer}>
            <div className={styles.footer_bgfoot}>
              <div className={styles.footer_contfoot}>
                <ul>
                  <li>
                    <div className={styles.footerLogoBox}>
                      <img src={dsersLogo} />
                      <div className={styles.footerIconBox}>
                        <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">
                          <img src={footerImg01} />
                        </a>
                        <a
                          href="https://www.facebook.com/DSers-2079192392398537/"
                          target="_blank"
                        >
                          <img src={footerImg02} />
                        </a>
                        <a
                          href="https://www.youtube.com/channel/UCpiRdgB8VXvaht0AizSP9mA"
                          target="_blank"
                        >
                          <img src={footerImg03} />
                        </a>
                        <a
                          href="https://www.reddit.com/user/DSers-Official"
                          target="_blank"
                        >
                          <img src={footerImg04} />
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="/about">About us</a>
                  </li>
                  <li>
                    <a href="/support">Support</a>
                  </li>
                  <li>
                    <a href="/privacy">Privacy Policy</a>
                  </li>
                  <li>
                    <BackTop>
                      <img src={backTop} />
                    </BackTop>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
